<div class="row justify-content-center align-items-center">
  <!-- Phần chứa ảnh đại diện -->
  <div class="col-lg-6 col-md-6 col-sm-12 text-center">
    <img src="/assets/image/logo_login.png" alt="" class="img-fluid full-image" />
  </div>

  <!-- Phần chứa form đăng nhập -->
  <div
    class="col-lg-6 col-md-4 col-sm-12 d-flex justify-content-center align-items-center"
    style="min-height: 50vh"
  >
    <div class="card" style="width: 100%; max-width: 500px">
      <div class="card-body">
        <!-- Logo canh giữa trong form đăng nhập -->
        <div class="text-center mb-4">
          <img
            width="150"
            height="150"
            src="assets/image/logo.png"
            alt="{{ PageDescription }}"
            class="img-fluid"
          />
        </div>

        <!-- Tiêu đề và mô tả trang -->
        <h3 class="text-center">{{ PageTitle }}</h3>
        <p class="text-center">{{ PageDescription }}</p>

        <!-- Form đăng nhập -->

        <!-- Button đăng nhập với kích thước lớn hơn và cỡ chữ lớn hơn -->
        <div class="form-group mb-3">
          <button
            class="btn btn-info w-100"
            (click)="LoginWithUser()"
            style="font-size: 1.2rem; padding: 0.75rem"
          >
            Đăng nhập để xem chi tiết
          </button>
        </div>

   
        <div class="form-group mb-3">
          <button
            class="btn btn-primary w-100"
            (click)="ViewMapWithoutLogin()"
            style="font-size: 1.2rem; padding: 0.75rem"
          >
            Xem trực tiếp bản đồ
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
