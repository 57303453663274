import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-shared-layout',
  templateUrl: './login-shared-layout.component.html',
  styleUrls: ['./login-shared-layout.component.css']
})
export class LoginSharedLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
