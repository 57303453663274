import { Component, OnInit, Inject, ElementRef, ViewChild, HostListener, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { from } from 'rxjs';
import { distinct } from 'rxjs/operators';

import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import * as maplibregl from 'maplibre-gl';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-ban-do007',
  templateUrl: './ban-do007.component.html',
  styleUrls: ['./ban-do007.component.css']
})
export class BanDo007Component implements OnInit {
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  isResponsive: boolean = window.innerWidth <= 768;
  IsHoNuoi: boolean = true;
  IsTramQuanTrac: boolean = false;
  IsPhuongTienKhaiThac: boolean = false;
  IsGiong: boolean = false;
  IsVungNuoi: boolean = false;
  IsCuaHang: boolean = false;
  IsNhuyenTheFlag: boolean = false;
  IsLongBe: boolean = false;
  selectedNames: Map<string, string> = new Map();
  currentLocationMarker: maplibregl.Marker | null = null;
  isMenuClosed: boolean = false; // Ban đầu menu được mở

  constructor(
    public router: Router,
    private renderer: Renderer2,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public ToChucService: ToChucService,



  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkAndTogglePadding(); // Call function after route navigation ends
      }
      this.toggleMenu();
    });
  }

  ngOnInit(): void {
    this.DanhMucQuanHuyenSearch();
    this.LoadMapByQuanTracID();
    this.loadNames(environment.DanhMucToChucIDHoNuoi);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkAndTogglePadding();
       // this.addSidebarToggleClickListener();
      }
    });
  }

  DanhMucQuanHuyenSearch() {
    if (this.DanhMucQuanHuyenService.List) {
      if (this.DanhMucQuanHuyenService.List.length == 0) {
        this.ToChucService.IsShowLoading = true;
        this.DanhMucQuanHuyenService.GetAllToListAsync().subscribe(
          res => {
            this.DanhMucQuanHuyenService.List = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
            this.DanhMucQuanHuyenService.ListFilter = this.DanhMucQuanHuyenService.List;
            this.DanhMucXaPhuongSearch();
          },
          err => {
          },
          () => {
            this.ToChucService.IsShowLoading = false;
          }
        );
      }
    }
  }
  DanhMucXaPhuongSearch() {
    this.ToChucService.IsShowLoading = true;
    this.DanhMucXaPhuongService.BaseParameter.ParentID = this.ToChucService.BaseParameter.DanhMucQuanHuyenID;
    if (this.ToChucService.BaseParameter.DanhMucQuanHuyenID == 0) {
      this.DanhMucXaPhuongService.BaseParameter.ParentID = 0;
      this.ToChucService.BaseParameter.DanhMucXaPhuongID = 0;
    }
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        if (this.DanhMucXaPhuongService.List.length > 0) {
          this.ToChucService.BaseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongService.List[0].ID;
        }
      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }


  map: maplibregl.Map | undefined;

  @ViewChild('map', { static: false })
  private mapContainer!: ElementRef<HTMLElement>;



  ngAfterViewInit() {
    this.MapInitialization(1, environment.Longitude, environment.Latitude);
    this.getCurrentLocation();
  }

  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(ID, longitude, latitude) {
    let IDDate = new Date().toISOString();
    let zoom = 11;
    if (this.ToChucService.BaseParameter.DanhMucXaPhuongID > 0) {
      zoom = 13;
    }
    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }

    // Khởi tạo bản đồ
    if (ID == 0) {
      this.map = new maplibregl.Map({
        container: this.mapContainer.nativeElement,
        style: 'https://api.maptiler.com/maps/streets/style.json?key=' + environment.MaptilerAPIKey,
        center: [longitude, latitude],
        zoom: zoom,
        pitch: 45,
      });
    }
    if (ID == 1) {
      this.map = new maplibregl.Map({
        container: this.mapContainer.nativeElement,
        style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
        center: [longitude, latitude],
        zoom: zoom,
        pitch: 45,
      });
    }

    // Thêm nút điều hướng và fullscreen
    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    this.map.addControl(
      new maplibregl.FullscreenControl({})
    );

    // Lấy vị trí hiện tại khi người dùng nhấn nút
    //this.getCurrentLocation();


    // Thêm nguồn ảnh Hoàng Sa và Trường Sa
    this.map.on('load', () => {
      this.map.addSource("HoangSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa" + IDDate,
        "source": "HoangSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],
        ]
      });
      this.map.addLayer({
        "id": "TruongSa" + IDDate,
        "source": "TruongSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });
    });
  }

  getCurrentLocation() {
    const currentLocationBtn = document.getElementById('currentLocationBtn');
    currentLocationBtn?.addEventListener('click', () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const userLongitude = position.coords.longitude;
          const userLatitude = position.coords.latitude;

          // Kiểm tra nếu marker đã tồn tại, thì xóa marker
          if (this.currentLocationMarker) {
            this.currentLocationMarker.remove();
            this.currentLocationMarker = null;
          } else {
            // Nếu chưa có marker, tạo mới và thêm vào bản đồ
            const gifMarker = document.createElement('div');
            gifMarker.style.backgroundImage = 'url(assets/image/location_current.gif)'; // Đường dẫn đến ảnh GIF của bạn
            gifMarker.style.width = '45px'; // Kích thước marker
            gifMarker.style.height = '42px';
            gifMarker.style.backgroundSize = 'cover';
            gifMarker.style.zIndex = '1105'; // Đảm bảo GIF nằm trên các phần tử khác

            // Thêm marker vào bản đồ tại vị trí hiện tại
            this.currentLocationMarker = new maplibregl.Marker(gifMarker)
              .setLngLat([userLongitude, userLatitude])
              .addTo(this.map);

            // Di chuyển bản đồ đến vị trí hiện tại
            this.map.setCenter([userLongitude, userLatitude]);
            this.map.setZoom(15); // Zoom gần hơn khi đến vị trí hiện tại
          }
        }, (error) => {
          console.error('Error getting location', error);
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    });
  }

  MapLoad(ID: number) {
    let longitude = environment.Longitude;
    let latitude = environment.Latitude;
    if (this.currentLocationMarker) {
      this.currentLocationMarker.remove();
      this.currentLocationMarker = null;
    }
    if (this.IsVungNuoi) {
      this.MapLoadVungNuoi(ID);
    }
    else {
      this.MapInitialization(ID, longitude, latitude);
      this.MapLoadSub();
    }
  }
  MapLoadSub() {
    if (this.IsHoNuoi == true) {
      this.MapLoadHoNuoi();
    }
    if (this.IsTramQuanTrac == true) {
      this.MapLoadTramQuanTrac();
    }
    if (this.IsCuaHang == true) {
      this.MapLoadCuaHang();
    }
    if (this.IsPhuongTienKhaiThac == true) {
      this.MapLoadPhuongTienKhaiThac();
    }
    if (this.IsGiong == true) {
      this.MapLoadGiong();
    }
    if (this.IsNhuyenTheFlag == true) {
      this.MapLoadNhuyenThe();
    }
    if (this.IsLongBe == true) {
      this.MapLoadLongBe();
    }
  }

  MapLoadHoNuoi() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDHoNuoi;
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.BaseParameter.ToChucVungNuoiTrangThaiID = environment.InitializationNumber;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.ListHoNuoi = (res as ToChuc[]);
        let longitude = environment.Longitude;
        let latitude = environment.Latitude;
        this.loadNames(environment.DanhMucToChucIDHoNuoi);
        if (this.ToChucService.ListHoNuoi) {
          if (this.ToChucService.ListHoNuoi.length > 0) {
            longitude = this.DownloadService.GetKinhDo(Number(this.ToChucService.ListHoNuoi[0].KinhDo));
            latitude = this.DownloadService.GetViDo(Number(this.ToChucService.ListHoNuoi[0].ViDo));
          }
          this.map.flyTo({
            center: [longitude, latitude],
            zoom: 12,
            speed: 1.2,
            curve: 1,
            essential: true
          });
        }
        const backgroundImageMap: { [key: number]: string } = {
          3: "url(assets/image/Feed.png)",
          2: "url(assets/image/NotFeed.png)",
        };
        for (let i = 0; i < this.ToChucService.ListHoNuoi.length; i++) {

          let ToChuc = this.ToChucService.ListHoNuoi[i];

          // Giảm chiều rộng xuống 400px và bổ sung thêm thông tin thời tiết
          let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
          html += "</div>";
          html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

          html += "<table style='width: 100%; border-spacing: 0;'>";
          html += "<tr>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Combine Loại Hình and Trạng Thái Vùng Nuôi in the same row
          html += "<div style='padding: 2px; display: flex; justify-content: space-between; align-items: center;'>";
          // Trạng Thái Vùng Nuôi
          html += `<div style="display: flex; align-items: center;">
              <b style="font-size: 12px; margin-right: 5px; color: #0066CC;">Trạng Thái:</b>
              <span style="font-size: 12px;">
                  ${ToChuc.ToChucVungNuoiTrangThaiID === 1 ? `✅ ${ToChuc.ToChucVungNuoiTrangThaiName}` :
              ToChuc.ToChucVungNuoiTrangThaiID === 3 ? `✔️ ${ToChuc.ToChucVungNuoiTrangThaiName}` :
                `❌ ${ToChuc.ToChucVungNuoiTrangThaiName}`}
              </span>
           </div>`;
          html += "</div>"; // End of combined row
          html += "<div style='padding: 2px;'><b>Loại Hình :</b> <b> Hộ Nuôi </b></div>";
          html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
          html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
          html += "<div style='padding: 2px;'><b>Loại mẫu: </b>" + (ToChuc.Description ? ToChuc.Description : 'Dữ liệu đang cập nhật') + "</div>";
          html += "<div style='padding: 2px;'><b>Loại nuôi: </b>" + (ToChuc.DanhMucGiongName ? ToChuc.DanhMucGiongName : 'Dữ liệu đang cập nhật') + "</div>";
          html += "</td>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Thêm các chi tiết bổ sung nếu cần cho bên phải
          html += "</td>";
          html += "</tr>";
          html += "</table>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
          html += "</div>";
          // Quang Trac
          // Bảng con chứa thông tin môi trường và chỉ số
          html += "<table style='width: 100%; border-spacing: 5px; text-align: center; font-size: 12px; background-color: #FFFCE6; border-collapse: collapse;'>";

          if (ToChuc.DanhSachThongSoMoiTruong.length === 0) {
            // Hiển thị dòng thông báo nếu chưa có dữ liệu
            html = html + "<tr>";
            html = html + "<td colspan='3' style='padding: 5px; text-align: center;'>Dữ liệu đang cập nhật...</td>";
            html = html + "</tr>";
          } else {
            // Nếu có dữ liệu, hiển thị danh sách thông số môi trường
            let ngayCapNhat = new Date().toLocaleDateString();
            html += `
     <tr>
         <td colspan="2" rowspan="2" class="highlight" style="padding: 8px; background-color: #F3F2E3; border-radius: 10px; border: 1px solid #CDD3C1; font-weight: bold; color: #0066CC;">
             Cập nhật <br> ${ngayCapNhat}
         </td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Ammonium</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 7)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ kiềm</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 2)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Cd</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 11)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ mặn</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 3)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio spp.</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 4)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>DO</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 8)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ pH</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 1)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio PS</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 5)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>TSS</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 10)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Nhiệt độ</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 12)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Aeromonas</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 6)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>COD</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 9)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ trong</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 13)}</td>
     </tr>
     `;
          }
          html += "</table>";

          // Button 
          html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: center;">
                  <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                      <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
                  </button>
              </div>`;


          html += "</div>";

          let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
            .setMaxWidth("400px");  // Giảm chiều rộng xuống 400px

          longitude = this.DownloadService.GetKinhDo(Number(ToChuc.KinhDo));
          latitude = this.DownloadService.GetViDo(Number(ToChuc.ViDo));
          var el = document.createElement('div');
          el.style.backgroundImage = el.style.backgroundImage = backgroundImageMap[ToChuc.ToChucVungNuoiTrangThaiID] || "url(assets/image/IconHoNuoi.png)";
          el.style.width = '30px';
          el.style.height = '42px';
          let marker = new maplibregl.Marker({ element: el })
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);

          popup.on('open', () => {
            const directionsButton = document.getElementById('directionsButton');
            if (directionsButton) {
              directionsButton.addEventListener('click', () => {
                this.getDirections(longitude, latitude);
              });
            }
          });
        }


      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );
  }

  MapLoadLongBe() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDLongBe;
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucLongBeToListAsync().subscribe(
      res => {
        this.ToChucService.ListLongBe = (res as ToChuc[]);
        let longitude = environment.Longitude;
        let latitude = environment.Latitude;
        this.loadNames(environment.DanhMucToChucIDLongBe);
        if (this.ToChucService.ListLongBe) {
          if (this.ToChucService.ListLongBe.length > 0) {
            longitude = this.DownloadService.GetKinhDo(Number(this.ToChucService.ListLongBe[0].KinhDo));
            latitude = this.DownloadService.GetViDo(Number(this.ToChucService.ListLongBe[0].ViDo));
          }
          this.map.flyTo({
            center: [longitude, latitude],
            zoom: 12,
            speed: 1.2,
            curve: 1,
            essential: true
          });
        }

        for (let i = 0; i < this.ToChucService.ListLongBe.length; i++) {
          let ToChuc = this.ToChucService.ListLongBe[i];


          // Giảm chiều rộng xuống 400px và bổ sung thêm thông tin thời tiết
          let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
          html += "</div>";
          html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

          html += "<table style='width: 100%; border-spacing: 0;'>";
          html += "<tr>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          html += "<div style='padding: 2px;'><b>Loại Hình :</b> <b> Lồng Bè </b></div>";
          html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
          html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
          html += "<div style='padding: 2px;'><b>Loại mẫu: </b>" + (ToChuc.Description ? ToChuc.Description : 'Dữ liệu đang cập nhật') + "</div>";
          html += "<div style='padding: 2px;'><b>Loại nuôi: </b>" + (ToChuc.DanhMucGiongName ? ToChuc.DanhMucGiongName : 'Dữ liệu đang cập nhật') + "</div>";
          html += "</td>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Thêm các chi tiết bổ sung nếu cần cho bên phải
          html += "</td>";
          html += "</tr>";
          html += "</table>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
          html += "</div>";

          // Button 
          html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: center;">
             <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                 <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
             </button>
         </div>`;

          html += "</div>";

          let longitude = this.DownloadService.GetKinhDo(Number(ToChuc.KinhDo));
          let latitude = this.DownloadService.GetViDo(Number(ToChuc.ViDo));
          var el = document.createElement('div');
          el.style.backgroundImage = "url(assets/image/IconLongBe.png)";
          el.style.width = '30px';
          el.style.height = '42px';

          // Tạo popup với cấu trúc mặc định
          const popup = new maplibregl.Popup({ closeOnClick: false })
            .setLngLat([longitude, latitude])
            .setHTML(html)
            .setMaxWidth("600px")
            .addTo(this.map);

          let marker = new maplibregl.Marker({ element: el })
            .setLngLat([longitude, latitude])
            .setPopup(popup) // Gắn popup vào marker
            .addTo(this.map);
          popup.on('open', () => {
            const directionsButton = document.getElementById('directionsButton');
            if (directionsButton) {
              directionsButton.addEventListener('click', () => {
                this.getDirections(longitude, latitude);
              });
            }
          });
        }


      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );
  }

  MapLoadNhuyenThe() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDNuoiNhuyenThe;
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucNhuyenTheToListAsync().subscribe(
      res => {
        this.ToChucService.ListNhuyenThe = (res as ToChuc[]);
        let longitude = environment.Longitude;
        let latitude = environment.Latitude;
        this.loadNames(environment.DanhMucToChucIDNuoiNhuyenThe);
        if (this.ToChucService.ListNhuyenThe) {
          if (this.ToChucService.ListNhuyenThe.length > 0) {
            longitude = this.DownloadService.GetKinhDo(Number(this.ToChucService.ListNhuyenThe[0].KinhDo));
            latitude = this.DownloadService.GetViDo(Number(this.ToChucService.ListNhuyenThe[0].ViDo));
          }
          this.map.flyTo({
            center: [longitude, latitude],
            zoom: 12,
            speed: 1.2,
            curve: 1,
            essential: true
          });
        }

        for (let i = 0; i < this.ToChucService.ListNhuyenThe.length; i++) {

          let ToChuc = this.ToChucService.ListNhuyenThe[i];

          // Giảm chiều rộng xuống 400px và bổ sung thêm thông tin thời tiết
          let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
          html += "</div>";
          html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

          html += "<table style='width: 100%; border-spacing: 0;'>";
          html += "<tr>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          html += "<div style='padding: 2px;'><b>Loại Hình :</b> <b> Nhuyễn Thể </b></div>";
          html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
          html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
          html += "<div style='padding: 2px;'><b>Loại mẫu: </b>" + (ToChuc.Description ? ToChuc.Description : 'Dữ liệu đang cập nhật') + "</div>";

          html += "<div style='padding: 2px;'><b>Loại nuôi: </b>" + (ToChuc.DanhMucGiongName ? ToChuc.DanhMucGiongName : "Dữ liệu đang cập nhật") + "</div>";
          html += "</td>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Thêm các chi tiết bổ sung nếu cần cho bên phải
          html += "</td>";
          html += "</tr>";
          html += "</table>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
          html += "</div>";

          // Button 
          html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: center;">
             <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                 <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
             </button>
         </div>`;

          html += "</div>";

          let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
            .setMaxWidth("600px");

          longitude = this.DownloadService.GetKinhDo(Number(ToChuc.KinhDo));
          latitude = this.DownloadService.GetViDo(Number(ToChuc.ViDo));
          var el = document.createElement('div');
          el.style.backgroundImage = "url(assets/image/IconNhuyenThe.png)";
          el.style.width = '30px';
          el.style.height = '42px';
          let marker = new maplibregl.Marker({ element: el })
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);

          popup.on('open', () => {
            const directionsButton = document.getElementById('directionsButton');
            if (directionsButton) {
              directionsButton.addEventListener('click', () => {
                this.getDirections(longitude, latitude);
              });
            }
          });
        }

      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );
  }

  MapLoadTramQuanTrac() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDTramQuanTrac;
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.BaseParameter.ToChucVungNuoiTrangThaiID = environment.InitializationNumber;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.ListTramQuanTrac = (res as ToChuc[]);
        let longitude = environment.Longitude;
        let latitude = environment.Latitude;
        this.loadNames(environment.DanhMucToChucIDTramQuanTrac);
        if (this.ToChucService.ListTramQuanTrac) {
          if (this.ToChucService.ListTramQuanTrac.length > 0) {
            longitude = this.DownloadService.GetKinhDo(Number(this.ToChucService.ListTramQuanTrac[0].KinhDo));
            latitude = this.DownloadService.GetViDo(Number(this.ToChucService.ListTramQuanTrac[0].ViDo));
          }
          this.map.flyTo({
            center: [longitude, latitude],
            zoom: 12,
            speed: 1.2,
            curve: 1,
            essential: true
          });
        }


        for (let i = 0; i < this.ToChucService.ListTramQuanTrac.length; i++) {

          let ToChuc = this.ToChucService.ListTramQuanTrac[i];
          let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
          html += "</div>";
          html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

          html += "<table style='width: 100%; border-spacing: 0;'>";
          html += "<tr>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          html += "<div style='padding: 2px;'><b>Thông tin :</b> <b> Trạm Quang Trắc </b></div>";
          html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
          html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
          html += "<div style='padding: 2px;'><b>Dữ Liệu thu thập: </b>" + (ToChuc.Description ? ToChuc.Description : "Dữ liệu đang cập nhật") + "</div>";
          html += "</td>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Thêm các chi tiết bổ sung nếu cần cho bên phải
          html += "</td>";
          html += "</tr>";
          html += "</table>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
          html += "</div>";

          // Bảng con chứa thông tin môi trường và chỉ số
          html += "<table style='width: 100%; border-spacing: 5px; text-align: center; font-size: 12px; background-color: #FFFCE6; border-collapse: collapse;'>";

          if (ToChuc.DanhSachThongSoMoiTruong.length === 0) {
            // Hiển thị dòng thông báo nếu chưa có dữ liệu
            html = html + "<tr>";
            html = html + "<td colspan='3' style='padding: 5px; text-align: center;'>Dữ liệu đang cập nhật...</td>";
            html = html + "</tr>";
          } else {
            // Nếu có dữ liệu, hiển thị danh sách thông số môi trường
            let ngayCapNhat = new Date().toLocaleDateString();
            html += `
              <tr>
                  <td colspan="2" rowspan="2" class="highlight" style="padding: 8px; background-color: #F3F2E3; border-radius: 10px; border: 1px solid #CDD3C1; font-weight: bold; color: #0066CC;">
                      Cập nhật <br> ${ngayCapNhat}
                  </td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Ammonium</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 7)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ kiềm</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 2)}</td>
              </tr>
              <tr>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Cd</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 11)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ mặn</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 3)}</td>
              </tr>
              <tr>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio spp.</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 4)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>DO</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 8)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ pH</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 1)}</td>
              </tr>
              <tr>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio PS</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 5)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>TSS</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 10)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Nhiệt độ</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 12)}</td>
              </tr>
              <tr>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Aeromonas</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 6)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>COD</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 9)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ trong</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 13)}</td>
              </tr>
              `;
            //}
          }
          html += "</table>";

          // Button 
          html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: center;">
             <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                 <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
             </button>
         </div>`;
          html = html + "</div>"; // Kết thúc div chứa tất cả

          let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
            .setMaxWidth("600px");

          let longitude = this.DownloadService.GetKinhDo(Number(ToChuc.KinhDo));
          let latitude = this.DownloadService.GetViDo(Number(ToChuc.ViDo));
          var el = document.createElement('div');
          el.style.backgroundImage = "url(assets/image/IconTramQuanTrac.png)";
          el.style.width = '30px';
          el.style.height = '42px';
          let marker = new maplibregl.Marker({ element: el })
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);
          popup.on('open', () => {
            const directionsButton = document.getElementById('directionsButton');
            if (directionsButton) {
              directionsButton.addEventListener('click', () => {
                this.getDirections(longitude, latitude);
              });
            }
          });
        }

      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );
  }

  getThongSoValue(danhSachThongSoMoiTruong: any[], id: number): string {
    const thongSo = danhSachThongSoMoiTruong.find(ts => ts.DanhMucChiTieuMoiTruongID === id);
    return thongSo ? `${thongSo.SoLieu} ${thongSo.DonViTinh}` : 'N/A';
  }

  MapLoadCuaHang() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDCuaHang;
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.BaseParameter.ToChucVungNuoiTrangThaiID = environment.InitializationNumber;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.ListCuaHang = (res as ToChuc[]);
        let longitude = environment.Longitude;
        let latitude = environment.Latitude;
        this.loadNames(environment.DanhMucToChucIDCuaHang);
        if (this.ToChucService.ListCuaHang) {
          if (this.ToChucService.ListCuaHang.length > 0) {
            longitude = this.DownloadService.GetKinhDo(Number(this.ToChucService.ListCuaHang[0].KinhDo));
            latitude = this.DownloadService.GetViDo(Number(this.ToChucService.ListCuaHang[0].ViDo));
          }
          this.map.flyTo({
            center: [longitude, latitude],
            zoom: 12,
            speed: 1.2,
            curve: 1,
            essential: true
          });
        }


        for (let i = 0; i < this.ToChucService.ListCuaHang.length; i++) {

          let ToChuc = this.ToChucService.ListCuaHang[i];

          let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
          html += "</div>";
          html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

          html += "<table style='width: 100%; border-spacing: 0;'>";
          html += "<tr>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          html += "<div style='padding: 2px;'><b>Loại Hình :</b> <b> Cửa Hàng </b></div>";
          html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
          html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
          html += "<div style='padding: 2px;'><b>Số Điện Thoại: </b>" + ToChuc.DienThoai + "</div>";
          html += "<div style='padding: 2px;'><b>Địa Chỉ: </b>" + ToChuc.DiaChi + "</div>";
          html += "</td>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Thêm các chi tiết bổ sung nếu cần cho bên phải
          html += "</td>";
          html += "</tr>";
          html += "</table>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
          html += "</div>";
          // Button 
          html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: center;">
             <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                 <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
             </button>
         </div>`;
          html = html + "</div>";

          let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
            .setMaxWidth("600px");

          longitude = this.DownloadService.GetKinhDo(Number(ToChuc.KinhDo));
          latitude = this.DownloadService.GetViDo(Number(ToChuc.ViDo));
          var el = document.createElement('div');
          el.style.backgroundImage = "url(assets/image/IConCuaHang.png)";
          el.style.width = '30px';
          el.style.height = '42px';
          let marker = new maplibregl.Marker({ element: el })
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);
          popup.on('open', () => {
            const directionsButton = document.getElementById('directionsButton');
            if (directionsButton) {
              directionsButton.addEventListener('click', () => {
                this.getDirections(longitude, latitude);
              });
            }
          });
        }

      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );
  }

  MapLoadPhuongTienKhaiThac() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDPhuongTienKhaiThac;
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.BaseParameter.ToChucVungNuoiTrangThaiID = environment.InitializationNumber;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.ListPhuongTienKhaiThac = (res as ToChuc[]);
        let longitude = environment.Longitude;
        let latitude = environment.Latitude;
        this.loadNames(environment.DanhMucToChucIDPhuongTienKhaiThac);
        if (this.ToChucService.ListPhuongTienKhaiThac) {
          if (this.ToChucService.ListPhuongTienKhaiThac.length > 0) {
            longitude = this.DownloadService.GetKinhDo(Number(this.ToChucService.ListPhuongTienKhaiThac[0].KinhDo));
            latitude = this.DownloadService.GetViDo(Number(this.ToChucService.ListPhuongTienKhaiThac[0].ViDo));
          }
          this.map.flyTo({
            center: [longitude, latitude],
            zoom: 12,
            speed: 1.2,
            curve: 1,
            essential: true
          });
        }

        for (let i = 0; i < this.ToChucService.ListPhuongTienKhaiThac.length; i++) {

          let ToChuc = this.ToChucService.ListPhuongTienKhaiThac[i];
          const diaChi = `${ToChuc?.SoNha ?? ""} ${ToChuc?.DuongPho ?? ""} ${ToChuc?.DanhMucXaPhuongName ?? ""} ${ToChuc?.DanhMucQuanHuyenName ?? ""}`;
          let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
          html += "</div>";
          html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

          html += "<table style='width: 100%; border-spacing: 0;'>";
          html += "<tr>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          html += "<div style='padding: 2px;'><b>Loại Hình :</b> <b> Phương  Tiện Khai Thác</b></div>";
          html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
          html += "<div style='padding: 2px;'><b>Thông tin phương tiện : </b>" + ToChuc.Note ? ToChuc.Note : "Dữ liệu đang cập nhật" + "</div>";
          html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
          html += "<div style='padding: 2px;'><b>Số Điện Thoại: </b>" + ToChuc.DienThoai + "</div>";
          html += "<div style='padding: 2px;'><b>Địa Chỉ: </b>" + diaChi + "</div>";
          html += "</td>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Thêm các chi tiết bổ sung nếu cần cho bên phải
          html += "</td>";
          html += "</tr>";
          html += "</table>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
          html += "</div>";
          // Button 
          html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: center;">
             <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                 <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
             </button>
         </div>`;
          html = html + "</div>";

          let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
            .setMaxWidth("600px");

          longitude = this.DownloadService.GetKinhDo(Number(ToChuc.KinhDo));
          latitude = this.DownloadService.GetViDo(Number(ToChuc.ViDo));
          var el = document.createElement('div');
          el.style.backgroundImage = "url(assets/image/IconPhuongTienKhaiThac.png)";
          el.style.width = '30px';
          el.style.height = '42px';
          let marker = new maplibregl.Marker({ element: el })
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);
          popup.on('open', () => {
            const directionsButton = document.getElementById('directionsButton');
            if (directionsButton) {
              directionsButton.addEventListener('click', () => {
                this.getDirections(longitude, latitude);
              });
            }
          });
        }

      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );
  }

  MapLoadGiong() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDGiong;
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.BaseParameter.ToChucVungNuoiTrangThaiID = environment.InitializationNumber;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.ListGiong = (res as ToChuc[]);
        let longitude = environment.Longitude;
        let latitude = environment.Latitude;
        this.loadNames(environment.DanhMucToChucIDGiong);
        if (this.ToChucService.ListGiong) {
          if (this.ToChucService.ListGiong.length > 0) {
            longitude = this.DownloadService.GetKinhDo(Number(this.ToChucService.ListGiong[0].KinhDo));
            latitude = this.DownloadService.GetViDo(Number(this.ToChucService.ListGiong[0].ViDo));
          }

          this.map.flyTo({
            center: [longitude, latitude],
            zoom: 12,
            speed: 1.2,
            curve: 1,
            essential: true
          });
        }


        for (let i = 0; i < this.ToChucService.ListGiong.length; i++) {

          let ToChuc = this.ToChucService.ListGiong[i];

          let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
          html += "</div>";
          html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

          html += "<table style='width: 100%; border-spacing: 0;'>";
          html += "<tr>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          html += "<div style='padding: 2px;'><b>Loại Hình :</b> <b> Cơ sở sản xuất giống </b></div>";
          html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
          html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
          html += "<div style='padding: 2px;'><b>Số Điện Thoại: </b>" + ToChuc.DienThoai + "</div>";
          html += "<div style='padding: 2px;'><b>Địa Chỉ: </b>" + ToChuc.DiaChi + "</div>";
          html += "</td>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Thêm các chi tiết bổ sung nếu cần cho bên phải
          html += "</td>";
          html += "</tr>";
          html += "</table>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
          html += "</div>";
          // Button 
          html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: center;">
            <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
            </button>
        </div>`;
          html = html + "</div>";

          let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
            .setMaxWidth("600px");

          longitude = this.DownloadService.GetKinhDo(Number(ToChuc.KinhDo));
          latitude = this.DownloadService.GetViDo(Number(ToChuc.ViDo));
          var el = document.createElement('div');
          el.style.backgroundImage = "url(assets/image/IconGiong.png)";
          el.style.width = '30px';
          el.style.height = '42px';
          let marker = new maplibregl.Marker({ element: el })
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);
          popup.on('open', () => {
            const directionsButton = document.getElementById('directionsButton');
            if (directionsButton) {
              directionsButton.addEventListener('click', () => {
                this.getDirections(longitude, latitude);
              });
            }
          });
        }

      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );
  }

  MapLoadVungNuoi(ID: number) {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDHoNuoi;
    this.ToChucService.BaseParameter.Active = false;
    this.ToChucService.BaseParameter.ToChucVungNuoiTrangThaiID = environment.InitializationNumber;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.ListVungNuoi = (res as ToChuc[]);
        let longitude = environment.Longitude;
        let latitude = environment.Latitude;
        this.ToChucService.TotalRowsVungNuoiGetByHoNuoi = this.groupByToChucCode(this.ToChucService.ListVungNuoi);
        this.loadNames(environment.DanhMucToChucIDHoNuoi);
        if (this.ToChucService.ListVungNuoi) {
          if (this.ToChucService.ListVungNuoi.length > 0) {
            longitude = this.DownloadService.GetKinhDo(Number(this.ToChucService.ListVungNuoi[0].KinhDo));
            latitude = this.DownloadService.GetViDo(Number(this.ToChucService.ListVungNuoi[0].ViDo));
          }
          this.map.flyTo({
            center: [longitude, latitude],
            zoom: 12,
            speed: 1.2,
            curve: 1,
            essential: true
          });
        }
        this.MapInitialization(ID, longitude, latitude);
        this.MapLoadSub();

        let ListToChucID = [...new Map(this.ToChucService.ListVungNuoi.map(item =>
          [item["ID"], item])).values()];


        for (let i = 0; i < ListToChucID.length; i++) {
          let ListToChuc = this.ToChucService.ListVungNuoi.filter((item: any) => item.ID == ListToChucID[i].ID);
          if (ListToChuc) {
            if (ListToChuc.length > 0) {

              let listToaDoPolygon = [];
              let listPolygon = [];
              for (let j = 0; j < ListToChuc.length; j++) {
                let longitudeSub1 = this.DownloadService.GetKinhDo(Number(ListToChuc[j].KinhDo));
                let latitudeSub1 = this.DownloadService.GetViDo(Number(ListToChuc[j].ViDo));
                listToaDoPolygon.push([longitudeSub1, latitudeSub1]);
              }
              listPolygon.push(listToaDoPolygon);

              let ToChuc = ListToChuc[0];
              let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
              html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
              html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
              html += "</div>";
              html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

              html += "<table style='width: 100%; border-spacing: 0;'>";
              html += "<tr>";
              html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
              // Combine Loại Hình and Trạng Thái Vùng Nuôi in the same row
              html += "<div style='padding: 2px; display: flex; justify-content: space-between; align-items: center;'>";
              // Loại Hình
              // Trạng Thái Vùng Nuôi
              html += `<div style="display: flex; align-items: center;">
                  <b style="font-size: 12px; margin-right: 5px; color: #0066CC;">Trạng Thái:</b>
                  <span style="font-size: 12px;">
                      ${ToChuc.ToChucVungNuoiTrangThaiID === 1 ? `✅ ${ToChuc.ToChucVungNuoiTrangThaiName}` :
                  ToChuc.ToChucVungNuoiTrangThaiID === 3 ? `✔️ ${ToChuc.ToChucVungNuoiTrangThaiName}` :
                    `❌ ${ToChuc.ToChucVungNuoiTrangThaiName}`}
                  </span>
               </div>`;
              html += "</div>"; // End of combined row
              html += "<div style='padding: 2px;'><b>Loại Hình :</b> <b> Vùng Nuôi của :" + ToChuc.Name + "</b></div>";
              html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
              html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
              html += "<div style='padding: 2px;'><b>Loại mẫu: </b>" + (ToChuc.Description ? ToChuc.Description : 'Dữ liệu đang cập nhật') + "</div>";
              html += "<div style='padding: 2px;'><b>Loại nuôi: </b>" + (ToChuc.DanhMucGiongName ? ToChuc.DanhMucGiongName : 'Dữ liệu đang cập nhật') + "</div>";
              html += "</td>";
              html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
              // Thêm các chi tiết bổ sung nếu cần cho bên phải
              html += "</td>";
              html += "</tr>";
              html += "</table>";

              // Quang Trac
              // Bảng con chứa thông tin môi trường và chỉ số
              html += "<table style='width: 100%; border-spacing: 5px; text-align: center; font-size: 12px; background-color: #FFFCE6; border-collapse: collapse;'>";

              if (ToChuc.DanhSachThongSoMoiTruong.length === 0) {
                // Hiển thị dòng thông báo nếu chưa có dữ liệu
                html = html + "<tr>";
                html = html + "<td colspan='3' style='padding: 5px; text-align: center;'>Dữ liệu đang cập nhật...</td>";
                html = html + "</tr>";
              } else {
                // Nếu có dữ liệu, hiển thị danh sách thông số môi trường
                let ngayCapNhat = new Date().toLocaleDateString();
                html += `
     <tr>
         <td colspan="2" rowspan="2" class="highlight" style="padding: 8px; background-color: #F3F2E3; border-radius: 10px; border: 1px solid #CDD3C1; font-weight: bold; color: #0066CC;">
             Cập nhật <br> ${ngayCapNhat}
         </td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Ammonium</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 7)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ kiềm</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 2)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Cd</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 11)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ mặn</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 3)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio spp.</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 4)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>DO</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 8)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ pH</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 1)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio PS</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 5)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>TSS</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 10)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Nhiệt độ</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 12)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Aeromonas</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 6)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>COD</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 9)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ trong</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 13)}</td>
     </tr>
     `;
              }
              html += "</table>";
              // Button 
              // Button 
              html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: center;">
          <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
              <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
          </button>
      </div>`;
              html += "</div>";

              let layerID = 'Layer_' + ToChuc.ID;
              let sourceID = 'Source_' + ToChuc.ID;

              this.map.on('load', () => {
                this.map.addSource(sourceID, {
                  'type': 'geojson',
                  'data': {
                    'type': 'Feature',
                    'properties': {
                      "name": ToChuc.Name,
                      "address": ToChuc.DiaChi,
                    },
                    'geometry': {
                      'type': 'Polygon',
                      'coordinates': listPolygon,
                    }
                  }
                });
                let color = this.DownloadService.GetRandomColor(ListToChuc.length);
                this.map.addLayer({
                  'id': layerID,
                  'type': 'fill',
                  'source': sourceID,
                  'paint': {
                    'fill-color': color,
                    'fill-opacity': 0.5,
                    'fill-outline-color': color,
                  }
                });
              });

              let mapSub = this.map;
              this.map.on('click', layerID, function (e) {
                this.map = mapSub;
                let popup = new maplibregl.Popup({ offset: 25 })
                  .setLngLat(e.lngLat)
                  .setHTML(html)
                  .setMaxWidth("600px")
                  .addTo(this.map);
              });
            }
          }
        }

      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );

  }

  groupByToChucCode(vungnuois: ToChuc[]): number {
    if (vungnuois.length > 0) {
      // Sử dụng reduce để nhóm phần tử theo 'Code'
      const groupedByCode = vungnuois.reduce((result: { [key: string]: ToChuc[] }, currentValue: ToChuc) => {
        const code = currentValue.Code;

        // Nếu chưa có nhóm cho 'Code', thì tạo mảng mới
        if (!result[code]) {
          result[code] = [];
        }

        // Thêm phần tử hiện tại vào nhóm tương ứng với 'Code'
        result[code].push(currentValue);

        return result; // Trả về đối tượng kết quả để tiếp tục quá trình nhóm
      }, {} as { [key: string]: ToChuc[] }); // Khởi tạo kết quả là một đối tượng rỗng

      // Trả về tổng số nhóm bằng cách lấy số lượng key trong đối tượng groupedByCode
      return Object.keys(groupedByCode).length;
    }
    return 0; // Trả về 0 nếu không có phần tử
  }

  MapLoadQuangTracByID(ToChucID: number) {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDTramQuanTrac;
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.BaseParameter.DanhMucQuanHuyenID = 0;
    this.ToChucService.BaseParameter.DanhMucXaPhuongID = 0;
    this.ToChucService.BaseParameter.ToChucVungNuoiTrangThaiID = environment.InitializationNumber;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.ListTramQuanTrac = (res as ToChuc[]);
        let ToChuc = this.ToChucService.ListTramQuanTrac.find(t => t.ID === ToChucID);
        this.loadNames(environment.DanhMucToChucIDTramQuanTrac);
        if (ToChuc) {
          let longitude = this.DownloadService.GetKinhDo(Number(ToChuc.KinhDo));
          let latitude = this.DownloadService.GetViDo(Number(ToChuc.ViDo));

          this.map.flyTo({
            center: [longitude, latitude],  // Tọa độ của trạm quan trắc
            zoom: 12,                        // Mức zoom, có thể tùy chỉnh
            speed: 1.2,                      // Tốc độ di chuyển
            curve: 1,                        // Độ cong của đường bay (1 là mặc định, giá trị cao hơn sẽ làm đường bay cong hơn)
            essential: true                  // Điều này đảm bảo flyTo vẫn hoạt động trong chế độ truy cập (accessibility mode)
          });

          let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
          html += "</div>";
          html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

          html += "<table style='width: 100%; border-spacing: 0;'>";
          html += "<tr>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          html += "<div style='padding: 2px;'><b>Thông tin :</b> <b> Trạm Quang Trắc </b></div>";
          html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
          html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
          html += "<div style='padding: 2px;'><b>Dữ Liệu thu thập: </b>" + (ToChuc.Description ? ToChuc.Description : "Dữ liệu đang cập nhật") + "</div>";
          html += "</td>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Thêm các chi tiết bổ sung nếu cần cho bên phải
          html += "</td>";
          html += "</tr>";
          html += "</table>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
          html += "</div>";

          // Bảng con chứa thông tin môi trường và chỉ số
          html += "<table style='width: 100%; border-spacing: 5px; text-align: center; font-size: 12px; background-color: #FFFCE6; border-collapse: collapse;'>";

          if (ToChuc.DanhSachThongSoMoiTruong.length === 0) {
            // Hiển thị dòng thông báo nếu chưa có dữ liệu
            html = html + "<tr>";
            html = html + "<td colspan='3' style='padding: 5px; text-align: center;'>Dữ liệu đang cập nhật...</td>";
            html = html + "</tr>";
          } else {
            // Nếu có dữ liệu, hiển thị danh sách thông số môi trường
            let ngayCapNhat = new Date().toLocaleDateString();
            html += `
              <tr>
                  <td colspan="2" rowspan="2" class="highlight" style="padding: 8px; background-color: #F3F2E3; border-radius: 10px; border: 1px solid #CDD3C1; font-weight: bold; color: #0066CC;">
                      Cập nhật <br> ${ngayCapNhat}
                  </td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Ammonium</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 7)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ kiềm</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 2)}</td>
              </tr>
              <tr>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Cd</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 11)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ mặn</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 3)}</td>
              </tr>
              <tr>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio spp.</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 4)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>DO</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 8)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ pH</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 1)}</td>
              </tr>
              <tr>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio PS</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 5)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>TSS</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 10)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Nhiệt độ</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 12)}</td>
              </tr>
              <tr>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Aeromonas</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 6)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>COD</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 9)}</td>
                  <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ trong</td>
                  <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 13)}</td>
              </tr>
              `;
            //}
          }
          html += "</table>";

             // Button 
             html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: center;">
             <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                 <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
             </button>
         </div>`;
          html = html + "</div>"; // Kết thúc div chứa tất cả
          let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
            .setMaxWidth("600px");

          var el = document.createElement('div');
          el.style.backgroundImage = "url(assets/image/IconTramQuanTrac.png)";
          el.style.width = '30px';
          el.style.height = '42px';
          let marker = new maplibregl.Marker({ element: el })
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);

          popup.on('open', () => {
            const directionsButton = document.getElementById('directionsButton');
            if (directionsButton) {
              directionsButton.addEventListener('click', () => {
                this.getDirections(longitude, latitude);
              });
            }
          });

        } else {
          alert("Không xác định được tọa độ của trạm quan trắc.");
        }
      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );
  }

  getDirections(longitude: number, latitude: number) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const currentLatitude = position.coords.latitude;
        const currentLongitude = position.coords.longitude;

        // Mở Google Maps với đường đi từ vị trí hiện tại đến vị trí trạm quan trắc
        const url = `https://www.google.com/maps/dir/${currentLatitude},${currentLongitude}/${latitude},${longitude}`;
        window.open(url, '_blank');
      }, function (error) {
        alert('Không thể xác định vị trí của bạn.');
      });
    } else {
      alert('Trình duyệt của bạn không hỗ trợ định vị địa lý.');
    }
  }

  LoadMapByQuanTracID() {
    this.ToChucService.currentToChucId.subscribe(toChucId => {
      if (toChucId) {
        // Gọi hàm MapLoadTramQuanTrac với ToChucID nhận được
        this.MapLoadQuangTracByID(toChucId);
      }
    });
  }

  async loadNames(danhMucID: number, danhMucQuanHuyen?: number, danhMucXaPhuong?: number) {
    this.selectedNames.clear(); // Reset Map

    // Gọi loadAll với danh mục tương ứng và lấy TotalRows
    await this.loadAll(danhMucID);

    const checkAndSet = (isChecked: boolean, list: any[], totalRow: number, name: string) => {
      if (isChecked) {
        if (list && list.length > 0 && totalRow > 0) {
          let validCount = list.filter(item => item.KinhDo && item.ViDo).length;

          if (this.ToChucService.TotalRowsVungNuoiGetByHoNuoi > 0 && name.trim() === "Vùng nuôi") {
            validCount = this.ToChucService.TotalRowsVungNuoiGetByHoNuoi;
          }
          this.selectedNames.set(name, `có tọa độ: ${validCount}, chưa có tọa độ: ${Math.max(0, totalRow - validCount)}`);
        } else {
          this.selectedNames.set(name, "Dữ liệu đang cập nhật.");
        }
      }
    };


    // Gọi checkAndSet cho từng danh mục, sử dụng TotalRows riêng biệt
    checkAndSet(this.IsHoNuoi, this.ToChucService.ListHoNuoi, this.ToChucService.TotalRowsHoNuoi, "Hộ nuôi");
    checkAndSet(this.IsVungNuoi, this.ToChucService.ListVungNuoi, this.ToChucService.TotalRowsVungNuoi, "Vùng nuôi");
    checkAndSet(this.IsPhuongTienKhaiThac, this.ToChucService.ListPhuongTienKhaiThac, this.ToChucService.TotalRowsPhuongTienKhaiThac, "Phương Tiện Khai Thác");
    checkAndSet(this.IsGiong, this.ToChucService.ListGiong, this.ToChucService.TotalRowsGiong, "Cơ sở sản xuất Giống");
    checkAndSet(this.IsCuaHang, this.ToChucService.ListCuaHang, this.ToChucService.TotalRowsCuaHang, "Cửa Hàng");
    checkAndSet(this.IsTramQuanTrac, this.ToChucService.ListTramQuanTrac, this.ToChucService.TotalRowsTramQuanTrac, "Trạm Quang Trắc");
    checkAndSet(this.IsLongBe, this.ToChucService.ListLongBe, this.ToChucService.TotalRowsLongBe, "Lồng Bè");
    checkAndSet(this.IsNhuyenTheFlag, this.ToChucService.ListNhuyenThe, this.ToChucService.TotalRowsNhuyenTheFlag, "Nhuyễn Thể");
  }


  loadAll(danhmucID: number): Promise<number> {
    return new Promise((resolve, reject) => {
      this.ToChucService.IsShowLoading = true;
      this.ToChucService.BaseParameter.ParentID = danhmucID;
      this.ToChucService.BaseParameter.Active = true;
      this.ToChucService.GetSQLByParentID_Active_SearchStringFull_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
        res => {
          const totalRows = (res as number);

          // Cập nhật TotalRows cho danh mục tương ứng
          switch (danhmucID) {
            case environment.DanhMucToChucIDHoNuoi:
              this.ToChucService.TotalRowsHoNuoi = totalRows;
              this.ToChucService.TotalRowsVungNuoi = totalRows;
              break;
            case environment.DanhMucToChucIDVungTrong:
              this.ToChucService.TotalRowsVungNuoi = totalRows;
              break;
            case environment.DanhMucToChucIDPhuongTienKhaiThac:
              this.ToChucService.TotalRowsPhuongTienKhaiThac = totalRows;
              break;
            case environment.DanhMucToChucIDGiong:
              this.ToChucService.TotalRowsGiong = totalRows;
              break;
            case environment.DanhMucToChucIDCuaHang:
              this.ToChucService.TotalRowsCuaHang = totalRows;
              break;
            case environment.DanhMucToChucIDTramQuanTrac:
              this.ToChucService.TotalRowsTramQuanTrac = totalRows;
              break;
            case environment.DanhMucToChucIDLongBe:
              this.ToChucService.TotalRowsLongBe = totalRows;
              break;
            case environment.DanhMucToChucIDNuoiNhuyenThe:
              this.ToChucService.TotalRowsNhuyenTheFlag = totalRows;
              break;
          }

          resolve(totalRows); // Kết thúc Promise và trả về totalRows
        },
        err => {
          reject(err); // Xử lý lỗi nếu có
        },
        () => {
          this.ToChucService.IsShowLoading = false;
        }
      );
    });
  }

  // Hàm toggle để mở/đóng menu
  toggleMenu() {
    this.isMenuClosed = !this.isMenuClosed;
  }

  addSidebarToggleClickListener() {
    if (this.toggleButton) {
      // Xóa các sự kiện click trước đó (nếu có)
      this.renderer.listen(this.toggleButton.nativeElement, 'click', () => {
        this.toggleSidebarHandler();
      });
    }
  }
  toggleSidebarHandler() {
    const sidebar = document.querySelector('.sidebar') as HTMLElement;
    if (sidebar) {
      sidebar.classList.toggle('active'); // Mở hoặc đóng sidebar bằng cách thêm/xóa class 'active'
    }
  }

  checkAndTogglePadding() {
    const mainContainer = document.querySelector('.main-container') as HTMLElement;

    // Check if the element exists before accessing its style property
    if (mainContainer) {
      // Điều kiện kiểm tra URL và kích thước màn hình
      if (this.router.url === '/LoginShared' || this.isResponsive) {
        mainContainer.style.padding = '0';
      } else {
        mainContainer.style.padding = '0 0 0 300px';
      }
    }
  }
  
}