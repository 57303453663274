<div class="row justify-content-center align-items-center">
  <!-- Phần chứa ảnh đại diện -->
  <div class="col-lg-6 col-md-6 col-sm-12 text-center">
    <img src="/assets/image/logo_login.png" alt="" class="img-fluid full-image" />
  </div>

  <!-- Phần chứa form đăng nhập -->
  <div
    class="col-lg-6 col-md-4 col-sm-12 d-flex justify-content-center align-items-center"
    style="min-height: 50vh"
  >
    <div class="card" style="width: 100%; max-width: 500px">
      <div class="card-body" style="margin-bottom: 180px;">
        <!-- Logo canh giữa trong form đăng nhập -->
        <div class="text-center mb-4">
          <img
            width="150"
            height="150"
            src="assets/image/logo.png"
            alt="{{ PageDescription }}"
            class="img-fluid"
          />
        </div>

        <!-- Tiêu đề và mô tả trang -->
        <h3 class="text-center">{{ PageTitle }}</h3>
        <p class="text-center">{{ PageDescription }}</p>
        <p class="text-center">{{ PageDescriptopn2 }}</p>
        <!-- Form đăng nhập -->

        <div class="form-group mb-3">
          <button
            class="btn btn-primary w-100 btn-custom-1"
            (click)="ViewMapWithoutLogin()"
            style="font-size: 1.2rem; padding: 0.75rem"
          >
          Xem bản đồ số
          </button>
        </div>
        <!-- Button đăng nhập với kích thước lớn hơn và cỡ chữ lớn hơn -->
        <div class="form-group mb-3">
          <button
            class="btn btn-info w-100 btn-custom-2"
            (click)="LoginWithUser()"
            style="font-size: 1.2rem; padding: 0.75rem;"
          >
          Cơ quan quản lý đăng nhập
          </button>
        </div>

        <p style="text-align: center;opacity: 0.5;">Phiên bản: 1124.V01</p>
      </div>
      <div class="version-info" >     
        <p style="font-weight: bold;">Thực hiện và Hỗ trợ kỹ thuật</p>
        <p>Công ty Cổ phần Đầu tư Digial Kingdom</p>
        <p>Website: <a style="color: blue;" href="https://dgk.vn/">https://dgk.vn/</a> | Email: <a style="color: blue" href="mailto:info@dgk.vn">info@dgk.vn</a></p>
    </div>    
    </div>
  </div>
</div>
