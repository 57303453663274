import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { DownloadService } from 'src/app/shared/Download.service';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucChucNang } from 'src/app/shared/DanhMucChucNang.model';
import { DanhMucChucNangService } from 'src/app/shared/DanhMucChucNang.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienToken } from 'src/app/shared/ThanhVienToken.model';
import { ThanhVienTokenService } from 'src/app/shared/ThanhVienToken.service';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { ThanhVienLichSuTruyCapService } from 'src/app/shared/ThanhVienLichSuTruyCap.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  constructor(
    public router: Router,
    private renderer: Renderer2
  ) {
  }
  ngOnInit(): void {
    this.addSidebarToggleClickListener();
  }

  ngAfterViewInit() {
  }

  addSidebarToggleClickListener() {
    if (this.toggleButton) {
      this.toggleButton.nativeElement.removeEventListener('click', this.toggleSidebarHandler);
      this.toggleButton.nativeElement.addEventListener('click', this.toggleSidebarHandler.bind(this));
    }
  }
  toggleSidebarHandler() {
    const sidebar = document.querySelector('.sidebar') as HTMLElement;
    if (sidebar) {
      sidebar.classList.toggle('active'); // Mở hoặc đóng sidebar bằng cách thêm/xóa class 'active'
    }
  }
  toggleSidebar() {
    const sidebar = document.querySelector('.sidebar') as HTMLElement;
    if (sidebar) {
      sidebar.classList.toggle('active'); // Thêm/lớp class active để mở/đóng sidebar
    }
  }
}